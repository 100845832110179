<template>
    <div class="callback">
        <div v-if="errorMessage" class="callback__error">
            <img src="@/assets/img/accessDenied.png"
                 class="callback__banner"
            >
            <h1 class="callback__title">
                Authorization failed
            </h1>
            <p class="text-sm text-gray-700 font-medium">
                {{ errorMessage }}
            </p>
            <router-link to="/" class="button mt-4">
                Go back
            </router-link>
        </div>
        <div v-else class="callback__loading">
            <Loader />
            <p class="callback__title mt-8">
                Working on authenticating you.
            </p>
            <p class="callback__title">
                Please wait...
            </p>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import Loader from '@/components/ui/Loader.vue';

export default {
    components: {
        Loader,
    },

    data() {
        return {
            message: null,
            error: null,
            success: null,
            errorMessage: null,
        };
    },

    async mounted() {
        try {
            await this.initAuth(this.$route.query);
        } catch (error) {
            this.errorMessage = error.message || error;
        }
    },

    methods: {
        ...mapActions('auth', ['authorizeGoogle']),
        ...mapActions('courses', ['fetchCourses']),
        ...mapActions('sockets', ['startSocketConnection']),

        async initAuth(query) {
            try {
                const user = await this.authorizeGoogle(query);
                if (!user) throw new Error('Authorization failed');
                await this.fetchCourses();
                this.$router.push({ path: '/home' });
                this.startSocketConnection();
            } catch (error) {
                this.errorMessage = error.message || error;
            }
        },
    },
};

</script>
<style lang="scss" scoped>
.callback {
    @apply h-screen;
    @apply w-screen;
    @apply flex;
    @apply items-center;
    @apply justify-center;

    &__error {
        @apply h-250;
        @apply w-250;
        @apply flex;
        @apply flex-col;
        @apply items-center;
        @apply justify-center;
    }

    &__banner {
        height: 50%;
    }

    &__loading {
        @apply h-100;
        @apply w-300;
        @apply flex;
        @apply flex-col;
        @apply items-center;
        @apply justify-evenly;
        @apply rounded-sm;
    }

    &__title {
        @apply text-gray-700;
        @apply font-medium;
    }
}

.button {
    @apply rounded-md;
    @apply shadow-sm;
    @apply h-12;
    @apply bg-white;
    @apply text-gray-700;
    @apply text-base;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply px-5;
    @apply font-medium;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply mx-2;
    &.left {
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
        @apply bg-gray-100;
        &:hover,&:focus {
            @apply text-gray-900;
        }
    }
    &.right {
       @apply bg-accent;
       @apply text-white;
    }
    &:hover{
        @apply outline-none;
    }
}
</style>
